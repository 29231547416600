#arkforge {
  @apply bg-[#000000] min-h-screen flex flex-col items-center text-white text-[20px];
}

#nova {
  background-image: url("/src/assets/img/bg/nova.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#aghs {
  background-image: url("/src/assets/img/bg/aghs.png");
  background-size: cover;

  background-repeat: no-repeat;
}
#rsg {
  background-image: url("/src/assets/img/bg/rsg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.text-carousel-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.carousel-slide {
  display: inline-block;
  margin-right: 50px;
  animation: scroll 15s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.crop {
  clip-path: inset(45% 15% 15% 15%);
}


.marquee {
  margin: 0 auto;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  &:before, &:after {
    position: absolute;
    top: 0;
    width: 50px;
    height: 30px;
    content: "";
    z-index: 1;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
}

.marquee__content {
  width: 300% !important;
  display: flex;
  line-height: 30px;
  animation: marquee 25s linear infinite forwards;
  &:hover {
    animation-play-state: paused;
  }
}

.list-inline {
  display: flex;
  justify-content: space-around;
  width: 200%;

  /* reset list */
  list-style: none;
  padding: 0;
  margin: 0;
}
@keyframes marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-66.6%); }
}


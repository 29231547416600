@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  .flexcenter {
    @apply flex items-center justify-center;
  }

  .flexcenter-col {
    @apply flex flex-col items-center justify-center;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .skeleton-screen {
    @apply h-4 animate-pulse rounded-md bg-slate-800 text-transparent;
  }

  .test {
    @apply border border-[#FFFFFF];
  }
}

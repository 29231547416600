@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@font-face {
  font-family: "airif";
  src: url("/src/assets/font/airif.otf") format("opentype");
}

@font-face {
  font-family: "titilium";
  src: url("/src/assets/font/titilium.ttf") format("truetype");
}

.airif {
  font-family: "titilium";
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.line-1{
    position: relative;
    /* border-right: 2px solid #CB0000; */
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
}

/* Animation */
.anim-typewriter{
  animation: typewriter 5s steps(44) 1s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal;
  -webkit-animation: typewriter 5s steps(44) 1s 1 normal both, blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 20em;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: #CB0000;
  }
  to {
    border-right-color: transparent;
  }
}

.typewriter-text {
  opacity: 0;
  transition: opacity 2s ease-in-out;
  animation: fadeIn 3.5s linear infinite;
}
.typewriter-text.active {
  opacity: 1;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.fade-text {
  animation: fadeIn 3.5s linear infinite;
  -webkit-animation: fadeIn 3.5s linear infinite;
}